import { Helmet } from "react-helmet";

const metaImg = "https://drawingalgorithms.com/img/preview2.gif";
const metaDesc = "What's an algorithm, anyway? We asked a variety of people to draw what they understood the word to mean.";

const About = () => {
	return (<div className="text-gray-900 max-w-xl">
		<Helmet>
			<title>Drawing Algorithms</title>
			<meta name="description" content={metaDesc} />

			<meta name="twitter:card" content="summary_large_image"></meta>
			<meta name="twitter:creator" content="@JaelleFuchs @eszter @bschne"></meta>

			<meta property="og:title" content="Drawing Algorithms" />
			<meta property="og:description" content={metaDesc} />
			<meta property="og:image" content={metaImg} />
		</Helmet>

		<p>
			These interviews were conducted as part of an ongoing research project at the <a className="cursor-pointer hover:text-yellow-500 hover:underline" href="https://www.ikmz.uzh.ch/en.html" target="_blank" rel="noopener noreferrer">Institute of Communication and Media Research</a> at University of Zurich.
		</p>

		<h2 className="font-bold text-2xl text-gray-900 mt-4">Research</h2>
		<p>
			<a className="cursor-pointer hover:text-yellow-500 hover:underline" href="https://www.ikmz.uzh.ch/en/research/divisions/internet-use-and-society/team/jaelle-fuchs.html" target="_blank" rel="noopener noreferrer">Jaelle Fuchs →</a>
		</p>
		<p>
			<a className="cursor-pointer hover:text-yellow-500 hover:underline" href="https://www.ikmz.uzh.ch/en/research/divisions/internet-use-and-society/team/eszter-hargittai.html" target="_blank" rel="noopener noreferrer">Eszter Hargittai →</a>
		</p>

		<h2 className="font-bold text-2xl text-gray-900 mt-4">Website</h2>
		<p>
			<a className="cursor-pointer hover:text-yellow-500 hover:underline" href="https://benjaminschneider.ch" target="_blank" rel="noopener noreferrer">Benjamin Schneider →</a>
		</p>
	</div>);
}

export default About;