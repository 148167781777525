import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Filter from './Components/Filter.jsx';

const Explore = () => {
	const [drawings, setDrawings] = useState([]);
	const [selectedTag, setSelectedTag] = useState("all");

	const filterOptions = ["all", "binary","black box","decision tree","if/then","input/output","list","math","other","pictorial","problem","process","program","repetition","spiral","technical system","wave","words"];

	// Fetch data
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const response = await fetch("/data/index/data.json");
		const data = await response.json();
		setDrawings(data.drawings);
	}

	const filteredDrawings = () => {
		if (selectedTag === "all") return drawings;

		return drawings.filter((dwg) => {
			return dwg.algorithm?.indexOf(selectedTag) !== -1 || 
				dwg.drawingCodes?.indexOf(selectedTag) !== -1
		});
	}

	return (
		<div>
			<div className="mb-4">
				<Filter label="Filter by Concept" options={filterOptions} selected={selectedTag} onSelectedChange={setSelectedTag} />
			</div>
			<div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
				{filteredDrawings().map(dwg => {
					if(dwg.refused === "true") {
						return (
							<div key={dwg.id} className="w-full h-32 bg-gray-700 relative overflow-hidden rounded">
								<div className="absolute w-full h-32 border-gray-700 border-4"></div>
							</div>
						)
					}
					return (
						<Link to={`/explore/${dwg.id}`}>
							<div key={dwg.id} className="w-full h-32 relative overflow-hidden">
								<div className="absolute w-full h-32 border-gray-700 border-4 hover:border-8"></div>
								<img src={`/img/drawings/thumbs/${dwg.drawing}`} className="w-full" />
							</div>
						</Link>
					)
				})}
			</div>
		</div>
	)
}

export default Explore;