import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

const Intro = (props) => {
	const navigate = useNavigate();

	const [drawings, setDrawings] = useState()
	const [drawing, setDrawing] = useState({});

	// Fetch data
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const response = await fetch("/data/index/data.json");
		const data = await response.json();
		setDrawings(data.drawings);
	}

	// Pick drawings to show
	useEffect(() => {
		if(typeof drawings === 'undefined') return;

		const pickDrawing = () => {
			let dwg = undefined;
			while (dwg === undefined) {
				let tmp = drawings[Math.floor(Math.random() * drawings.length)];
				console.log(drawings[0])
				console.log(tmp);
				if (tmp.refused === "false") {
					dwg = tmp;
				}
			}

			setDrawing({
				imageUrl: `/img/drawings/${dwg.drawing}`,
				description: dwg.narrativeSummary
			});
		}

		pickDrawing();
		setInterval(pickDrawing, 3000);
	}, [drawings])

	const metaImg = "https://drawingalgorithms.com/img/preview2.gif";
	const metaDesc = "What's an algorithm, anyway? We asked a variety of people to draw what they understood the word to mean.";

	return (
		<div className="md:grid md:grid-cols-12 md:gap-4 pb-8">
			<Helmet>
				<title>Drawing Algorithms</title>
				<meta name="description" content={metaDesc} />

				<meta name="twitter:card" content="summary_large_image"></meta>
				<meta name="twitter:creator" content="@JaelleFuchs @eszter @bschne"></meta>

				<meta property="og:title" content="Drawing Algorithms" />
				<meta property="og:description" content={metaDesc} />
				<meta property="og:image" content={metaImg} />
			</Helmet>

			<div className="col-span-6 order-last md:h-96 h-48 mb-16">
				<div className="mb-8">
					<img src={drawing.imageUrl} className="md:max-h-80 max-h-40 mx-auto drop-shadow shadow-lg rounded-md" />
					<p className="text-sm text-gray-500 mt-4 italic text-center">An algorithm, drawn by a {drawing.description}</p>
				</div>
			</div>
			<div className="col-span-6 pr-8">
				<p className="mb-4 text-xl font-bold text-gray-900 leading-relaxed">
					What's an algorithm, anyway?
				</p>
				<p className="mb-4 text-lg text-gray-900 leading-relaxed">
					We've all heard some definition or other at this point,
					but the word has also become a metaphor for everything from newsfeed rankings to automated decision making.
				</p>
				<p className="mb-4 text-lg text-gray-900 leading-relaxed">
					The highly technical (or pedantic) might debate what actually falls under the term.
					But technology has become extremely pervasive in all our lives,
					so wouldn't it be more interesting to find out what people <em className="italic">actually have in mind</em> when they hear the word?
				</p>
				<p className="mb-4 text-lg text-gray-900 leading-relaxed">
					This project asked a wide variety of participants to make a drawing representing an algorithm.
					Here, we have collected their responses and explanations for you to browse.
				</p>
				<a onClick={() => navigate('/explore')} className="mt-4 text-sm tracking-wider font-semibold uppercase inline-block p-4 cursor-pointer text-white bg-gray-900 hover:bg-gray-600 rounded hover:underline">Explore The Drawings →</a>
			</div>
		</div>
	)
}

export default Intro;