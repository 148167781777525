const Filter = ({label, options, selected, onSelectedChange}) => {
	return (
		<div>
			<span className="uppercase tracking-wider font-semibold text-gray-900">{label}</span>
			<div>
				{options.map((opt) => {
					const className = (opt === selected) ? 
						"mt-2 mr-2 text-xs tracking-wider font-semibold uppercase inline-block p-2 cursor-pointer text-white bg-gray-900 hover:bg-gray-600 rounded hover:underline" :
						"mt-2 mr-2 text-xs tracking-wider font-semibold uppercase inline-block p-2 cursor-pointer text-gray-900 bg-gray-300 rounded hover:underline"
					return (
						<a 
						onClick={() => onSelectedChange(opt)}
						className={className}>
							{opt}
						</a>
					)
				})}
			</div>
		</div>
	)
} 

export default Filter;