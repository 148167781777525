import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const DrawingDetails = (props) => {
	const { id } = useParams();
	const [data, setData] = useState({
		imageUrl: '',
		description: '',
		text: ''
	});

	// Fetch data
	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		// Request image URL from data
		const response = await fetch("/data/index/data.json");
		const data = await response.json();
		const drawing = data.drawings.find(dwg => dwg.id === id);
		
		// Request text data
		const textResponse = await fetch(`/data/transcripts/${drawing.transcript}`);
		const textData = await textResponse.text();

		setData({
			imageUrl: `/img/drawings/${drawing.drawing}`,
			text: textData,
			description: drawing.narrativeSummary
		});
	}

	const fetchTranscript = async (url) => {
		const response = await fetch(url);
		const data = await response.text();
		setData({
			text: data
		});
	}

	const navigate = useNavigate();

	const metaDesc = `An algorithm, drawn by a ${data.description}`;
	const metaImg = `https://drawingalgorithms.com${data.imageUrl}`;
	return (
		<div>
			<Helmet>
				<title>Drawing Algorithms</title>
				<meta name="description" content={metaDesc} />

				<meta name="twitter:card" content="summary_large_image"></meta>
				<meta name="twitter:creator" content="@JaelleFuchs @eszter @bschne"></meta>

				<meta property="og:title" content="Drawing Algorithms" />
				<meta property="og:description" content={metaDesc} />
				<meta property="og:image" content={metaImg} />
			</Helmet>
			<div className="pb-8">
				<a onClick={() => navigate(-1)} className="text-md py-2 p-r-2 cursor-pointer text-gray-500 hover:underline">← Go back</a>
				<h1 className="text-2xl text-gray-900 font-semibold mt-4 mb-2">An algorithm, drawn by a {data.description}</h1>
			</div>
			<div className="md:grid md:grid-cols-12 md:gap-4 pb-8">
				<div className="col-span-6 order-last">
					<div className="mb-8">
						<img src={data.imageUrl} className="w-full drop-shadow shadow-lg rounded-md" />
					</div>
				</div>
				<div className="col-span-6 text-gray-900 pr-8">
					<p className="text-lg text-gray-600" style={{whiteSpace: 'pre-line'}}>
						{data.text}
					</p>
				</div>
			</div>
		</div>
	)
}

export default DrawingDetails;