import { BrowserRouter, Route, Routes, NavLink, useNavigate } from "react-router-dom";
import About from './About.jsx'
import Explore from './Explore.jsx'
import Intro from './Intro.jsx'
import DrawingDetails from './DrawingDetails.jsx'

import './index.css';

const App = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-gray-900 py-12 text-white">
        <div className="container max-w-screen-xl mx-auto px-8">
          <header className="mt-2 lg:mt-8">
            <span className="text-3xl font-bold cursor-pointer" onClick={() => navigate('/')}>Drawing Algorithms</span>
            <div className="md:inline md:float-right mt-3.5">
              <NavLink to="/explore" className="mr-3 text-lg font-semibold hover:text-yellow-500 block md:inline" activeClassName="text-yellow-600">Explore</NavLink>
              <NavLink to="/about" className="text-lg font-semibold hover:text-yellow-500 block md:inline" activeClassName="text-yellow-600">About</NavLink>
            </div>
          </header>
        </div>
      </div>
      <div className="container max-w-screen-xl mx-auto px-8">
        <section className="py-8">
            <Routes>
              {/* Explore section*/}
              <Route path="/explore" element={<Explore />}/>
              <Route path="/explore/:id" element={<DrawingDetails />}/> 

              {/* "About" page */}
              <Route path="/about" exact element={<About />}/>

              <Route path="/" exact element={<Intro />} />
            </Routes>
        </section>
      </div>
    </div>
  );
}

export default App;